// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewChatInfo_new-chat-info__A-8wo {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NewChatInfo_new-chat-info__text__eus3N {
  font-size: 2.5rem;
  line-height: 2rem;
  font-weight: bold;
  color: #28156b;
}
.NewChatInfo_new-chat-info__mailes__2Wz-s {
  font-size: 6rem;
  color: rgb(184, 38, 28);
  font-weight: 100;
}
.NewChatInfo_new-chat-info__mailes__ai__g8bGI {
  font-size: 6.5rem;
  color: #28156b;
  font-weight: 700;
}
.NewChatInfo_new-chat-info__logo__DAzeG {
  height: 5rem;
  margin-bottom: 4.5rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/Chat/components/NewChatInfo/NewChatInfo.module.scss","webpack://./src/scss/_branding.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cCXY;ADUhB;AAKG;EACG,eAAA;EACA,uBAAA;EACA,gBAAA;AAHN;AAKM;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;AAHR;AAOE;EACE,YAAA;EACA,qBAAA;AALJ","sourcesContent":["@import '../../../../scss/variables';\n\n.new-chat-info {\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  &__text {\n    font-size: 2.5rem;\n    line-height: 2rem;\n    font-weight: bold;\n    color: $color-info;\n  }\n\n\n   &__mailes {\n      font-size: 6rem;\n      color: rgb(184, 38, 28);\n      font-weight: 100;\n  \n      &__ai {\n        font-size: 6.5rem;\n        color: #28156b;\n        font-weight: 700;\n      }\n    }\n\n  &__logo {\n    height: 5rem;\n    margin-bottom: 4.5rem;\n  }\n}\n","// Branding colors\n$branding-contrast: #e3f7d2;\n$branding-main: #28156b;\n$branding-secondary: #fdc1b2;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new-chat-info": `NewChatInfo_new-chat-info__A-8wo`,
	"new-chat-info__text": `NewChatInfo_new-chat-info__text__eus3N`,
	"new-chat-info__mailes": `NewChatInfo_new-chat-info__mailes__2Wz-s`,
	"new-chat-info__mailes__ai": `NewChatInfo_new-chat-info__mailes__ai__g8bGI`,
	"new-chat-info__logo": `NewChatInfo_new-chat-info__logo__DAzeG`
};
export default ___CSS_LOADER_EXPORT___;
