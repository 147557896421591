import {
  useNavigate,
} from "react-router-dom";
import { useIsAuthenticated, useMsal, } from '@azure/msal-react';
import { useEffect } from "react";

function AuthResponse() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal()

  useEffect(() => {
    if(isAuthenticated) {
      if(accounts.length > 0) {
        instance.setActiveAccount(accounts[0])
      }
      navigate('/')
    }
  }, [isAuthenticated, navigate, instance, accounts]);
  
  return  <></>;
}

export default AuthResponse;
