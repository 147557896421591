import AiResponseSourceContext from '../../../../contexts/AiResponseSourceContext';
import { useContext } from 'react';
import ResizableSidebar from '../../../../components/RightSidebar/ResizableSidebar';
import AiResponseSourceItem from './AiResponseSourceItem';

function AiResponseSource() {
  const context = useContext(AiResponseSourceContext);
  const { setSource } = useContext(AiResponseSourceContext);

  return (
    <ResizableSidebar
      isOpen={!!context.source?.length}
      title='Message context'
      onClose={() => setSource([])}
    >
      {context.source?.map((item, index) => (
        <AiResponseSourceItem key={index} item={item} />
      ))}
    </ResizableSidebar>
  );
}

export default AiResponseSource;
