import { useState } from 'react';
import { AiResponseSource } from '../../../../models/AiResponseModel';
import Button from '../../../../components/Button/Button';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import styles from './AiResponseSource.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  item: AiResponseSource;
}

function AiResponseSourceItem({ item }: Props) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  function formatDate(timeString: string) {
    const date = new Date(timeString);
    return date.toLocaleDateString('lt');
  }

  return (
    <div className={styles.item}>
      <div
          className={styles.item__toggle}
          onClick={toggleExpansion}
      >
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className={styles.icon} />
        {item.fileName}
      </div>
      {expanded && (
        <div className={styles.item__details}>
          <div className={styles.item__info}>
            <span className={styles.label}>Score:</span><span title={item.score.toString()}>{item.score}</span>
          </div>
          <p>{item.text}</p>
        </div>
      )}
    </div>
  );
}

export default AiResponseSourceItem;
