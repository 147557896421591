// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__DwtSp {
  width: 60px;
  height: 16px;
  display: flex;
  justify-content: space-between;
  animation: Loader_l3-0__xodgs 1s infinite alternate;
  align-self: center;
  justify-self: center;
}
.Loader_loader__DwtSp:before, .Loader_loader__DwtSp:after {
  content: "";
  width: 16px;
  background: #28156b;
  animation: Loader_l3-1__X9T7Q 0.5s infinite alternate;
}
.Loader_loader__DwtSp:after {
  background: #e3f7d2;
  --s: -1;
}

@keyframes Loader_l3-0__xodgs {
  0%, 40% {
    transform: rotate(0);
  }
  80%, 100% {
    transform: rotate(0.5turn);
  }
}
@keyframes Loader_l3-1__X9T7Q {
  80%, 100% {
    transform: translate(calc(var(--s, 1) * 14px));
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss","webpack://./src/scss/_branding.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mDAAA;EACA,kBAAA;EACA,oBAAA;AADF;AAGE;EAEE,WAAA;EACA,WAAA;EACA,mBCbY;EDcZ,qDAAA;AAFJ;AAKE;EACE,mBCnBgB;EDoBhB,OAAA;AAHJ;;AAOA;EACE;IAEE,oBAAA;EALF;EAOA;IAEE,0BAAA;EANF;AACF;AAQA;EACE;IAEE,8CAAA;EAPF;AACF","sourcesContent":["@import '../../scss/variables';\n\n.loader {\n  width: 60px;\n  height: 16px;\n  display: flex;\n  justify-content: space-between;\n  animation: l3-0 1s infinite alternate;\n  align-self: center;\n  justify-self: center;\n\n  &:before,\n  &:after {\n    content: '';\n    width: 16px;\n    background: $color-bg-loader-1;\n    animation: l3-1 0.5s infinite alternate;\n  }\n\n  &:after {\n    background: $color-bg-loader-2;\n    --s: -1;\n  }\n}\n\n@keyframes l3-0 {\n  0%,\n  40% {\n    transform: rotate(0);\n  }\n  80%,\n  100% {\n    transform: rotate(0.5turn);\n  }\n}\n@keyframes l3-1 {\n  80%,\n  100% {\n    transform: translate(calc(var(--s, 1) * 14px));\n  }\n}\n","// Branding colors\n$branding-contrast: #e3f7d2;\n$branding-main: #28156b;\n$branding-secondary: #fdc1b2;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__DwtSp`,
	"l3-0": `Loader_l3-0__xodgs`,
	"l3-1": `Loader_l3-1__X9T7Q`
};
export default ___CSS_LOADER_EXPORT___;
