// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginLogo_login-logo__epf\\+Z {
  height: 15%;
  min-height: 7rem;
  margin-bottom: -1.5rem;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/containers/Login/components/LoginLogo/LoginLogo.module.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,gBAAA;EACA,sBAAA;EACA,UAAA;AAFF","sourcesContent":["@import '../../../../scss/variables';\n@import '../../../../scss/mixins';\n\n.login-logo {\n  height: 15%;\n  min-height: 7rem;\n  margin-bottom: -1.5rem;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-logo": `LoginLogo_login-logo__epf+Z`
};
export default ___CSS_LOADER_EXPORT___;
