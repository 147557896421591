import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { history } from '../helpers/history';
import PrivateRoute from './PrivateRoute';
import Login from '../containers/Login/Login';
import PageLayout from '../components/PageLayout/PageLayout';
import Chat from '../containers/Chat/Chat';
import AuthResponse from '../containers/Login/AuthResponse';

function Routes(): JSX.Element {
  return (
    <Router history={history}>
      <RouterRoutes>
        <Route path='/' element={<PrivateRoute />}>
          <Route element={<PageLayout />}>
            <Route path='/' element={<Chat />} />
            <Route path='/:chatId' element={<Chat />} />
          </Route>
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/auth' element={<AuthResponse />} />
      </RouterRoutes>
    </Router>
  );
}

export default Routes;
