import styles from './LoginWrapper.module.scss';

interface Props {
  children: React.ReactNode;
}

function LoginWrapper({ children }: Props) {
  return <div className={styles['login-wrapper']}>{children}</div>;
}

export default LoginWrapper;
