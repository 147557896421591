// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_button__1Fra7 {
  background: transparent;
  border-radius: 0.25rem;
  border: none;
  color: #28156b;
  cursor: pointer;
  font-size: 16px;
  padding: 0.75rem;
  transition: all 0.2s ease-in-out;
}
.IconButton_button__1Fra7:hover {
  background-color: #fc9980;
}
.IconButton_button__1Fra7:hover[disabled] {
  background-color: transparent;
}
.IconButton_button__1Fra7[disabled] {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/IconButton/IconButton.module.scss","webpack://./src/scss/_branding.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,cCJc;EDKd,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gCAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAGI;EACE,6BAAA;AADN;AAME;EACE,eAAA;AAJJ","sourcesContent":["@import '../../scss/variables';\n\n.button {\n  background: transparent;\n  border-radius: 0.25rem;\n  border: none;\n  color: $color-icon-button;\n  cursor: pointer;\n  font-size: 16px;\n  padding: 0.75rem;\n  transition: all 0.2s ease-in-out;\n\n  &:hover {\n    background-color: darken($branding-secondary, 10%);\n\n    &[disabled] {\n      background-color: transparent;\n    }\n  }\n\n\n  &[disabled] {\n    cursor: default;\n  }\n}\n","// Branding colors\n$branding-contrast: #e3f7d2;\n$branding-main: #28156b;\n$branding-secondary: #fdc1b2;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `IconButton_button__1Fra7`
};
export default ___CSS_LOADER_EXPORT___;
