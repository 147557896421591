import axios from 'axios';
import { getUserId } from '../mockData/user';
import getAccesToken from './authService';

const baseURL = process.env.REACT_APP_API_URL;
const userId = getUserId();
const axiosInstance = axios.create({
  baseURL,
  headers: { userId: userId },
});

const chatService = {
  getAllChats: async () => {
    try {
      const token = await getAccesToken();
      const response = await axiosInstance.get('chats', {
        headers: { authorization: 'Bearer ' + token },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllChatMessages: async (chatId: string) => {
    try {
      const token = await getAccesToken();
      const response = await axiosInstance.get(`chats/${chatId}`, {
        headers: { authorization: 'Bearer ' + token },
      });
      await new Promise((resolve) => setTimeout(resolve, 350));
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteChat: async (chatId: string) => {
    try {
      const token = await getAccesToken();
      const response = await axiosInstance.delete(`chats/${chatId}`, {
        headers: { authorization: 'Bearer ' + token },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  sendMessageFeedback: async (
    chatLineId: string,
    feedbackText: string,
    score: number | undefined,
  ) => {
    try {
      const response = await axiosInstance.post(
        'chats/feedback',
        {
          chatLineId: chatLineId,
          score,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  regenerateAnswer: async (chatId: string) => {
    // TODO: get from settings
    let useCondenseContext = true;

    try {
      const token = await getAccesToken();
      const response = await axiosInstance.post(
        'chats/regenerate',
        {
          chatId, useCondenseContext
        },
        {
          headers: { authorization: 'Bearer ' + token },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default chatService;
