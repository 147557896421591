import styles from './Wrapper.module.scss';

interface Props {
  children: React.ReactNode;
}

function ChatWrapper({ children }: Props) {
  return <div className={styles.wrapper}>{children}</div>;
}

export default ChatWrapper;
