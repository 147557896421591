import styles from './SidebarFooter.module.scss';

interface Props {
  onLogout: () => void;
}

function SidebarFooter({ onLogout }: Props) {
  return (
    <div className={styles['sidebar-footer']}>
    </div>
  );
}

export default SidebarFooter;
