import { useMsal } from '@azure/msal-react';
import styles from './LoginButton.module.scss';

function LoginButton() {
  const { instance } = useMsal();

  const handleLogin = () => {
      instance.loginRedirect();
  };
  return (
    <button className={styles['login-button']}  onClick={() => handleLogin()}>Microsoft login</button>
  );
}
export default LoginButton;
