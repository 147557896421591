// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeedbackModal_feedback-modal__content__ygrae {
  display: flex;
  flex-direction: column;
}
.FeedbackModal_feedback-modal__textarea__EY6mb {
  resize: none;
  min-width: 30rem;
  width: 40vw;
  margin-top: 2rem;
}
.FeedbackModal_feedback-modal__scores__nrnwc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.FeedbackModal_feedback-modal__scores__score__hCLBf {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FeedbackModal_feedback-modal__scores__score__button__W3nHA {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border: 2px solid #28156b;
  border-radius: 8px;
  background: #28156b;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
}
.FeedbackModal_feedback-modal__scores__score__button__selected__DjhCk {
  background: #28a745;
  border-color: #28a745;
}`, "",{"version":3,"sources":["webpack://./src/containers/Chat/components/Feedback/FeedbackModal.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,sBAAA;AAAJ;AAGE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAFJ;AAII;EACE,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFN;AAIM;EACE,eAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,wCAAA;EACA,cAAA;AAFR;AAIQ;EACE,mBAAA;EACA,qBAAA;AAFV","sourcesContent":[".feedback-modal {\n  &__content {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__textarea {\n    resize: none;\n    min-width: 30rem;\n    width: 40vw;\n    margin-top: 2rem;\n  }\n\n  &__scores {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 2rem;\n\n    &__score {\n      width: 10%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n\n      &__button {\n        cursor: pointer;\n        padding: 0.75rem 1.5rem;\n        border: 2px solid #28156b;\n        border-radius: 8px;\n        background: #28156b;\n        color: white;\n        font-size: 1rem;\n        font-weight: 600;\n        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n        margin: 0.5rem;\n\n        &__selected {\n          background: #28a745;\n          border-color: #28a745;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback-modal__content": `FeedbackModal_feedback-modal__content__ygrae`,
	"feedback-modal__textarea": `FeedbackModal_feedback-modal__textarea__EY6mb`,
	"feedback-modal__scores": `FeedbackModal_feedback-modal__scores__nrnwc`,
	"feedback-modal__scores__score": `FeedbackModal_feedback-modal__scores__score__hCLBf`,
	"feedback-modal__scores__score__button": `FeedbackModal_feedback-modal__scores__score__button__W3nHA`,
	"feedback-modal__scores__score__button__selected": `FeedbackModal_feedback-modal__scores__score__button__selected__DjhCk`
};
export default ___CSS_LOADER_EXPORT___;
