import { useRef, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { useAppSelector } from '../../../../hooks/storeHooks';

import styles from './FeedbackModal.module.scss';
import classNames from 'classnames';

interface Props {
  isOpen: boolean;
  isPositive: boolean;
  onClose: () => void;
  onSubmit: (feedback: {score: number | undefined, feedback: string | undefined}) => void;
}

function FeedbackModal({ isOpen, isPositive, onClose, onSubmit }: Props) {
  const [selectedScore, setScore] = useState<number>();
  const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const loadingSendFeedback: boolean = useAppSelector(
    (state) => state.chat.loadingSendFeedback
  );

  function handleSubmitFeedback() {
    onSubmit({
      score: selectedScore, 
      feedback: textareaRef.current?.value
    });
  }

  function selectScore(score: number) {
    return () => {
      setScore(score)
    }
  }

  return (
    <Modal
      actionButtonTitle='Submit'
      isOpen={isOpen}
      isDisabled={loadingSendFeedback || !selectedScore}
      onClose={onClose}
      onAction={handleSubmitFeedback}
    >
      <div className={styles['feedback-modal__content']}>
        <h2>Feedback</h2>
        <div>Please rate the response form 1 to 10:</div>
        <div className={styles['feedback-modal__scores']}>
          {
            scores.map((score: number) => <div  key={score} className={styles['feedback-modal__scores__score']}>
                <button 
                 className={classNames(styles['feedback-modal__scores__score__button'], {
                  [styles['feedback-modal__scores__score__button__selected']]: score == selectedScore
                })}
                onClick={selectScore(score)}>{score}</button>
              </div>)
          }
        </div>
        <textarea
          id='feedback-input'
          placeholder='(Optional) Feel free to add specific feedback details'
          rows={10}
          className={styles['feedback-modal__textarea']}
          ref={textareaRef}
        />
      </div>
    </Modal>
  );
}

export default FeedbackModal;