import logo from '../../../../assets/logo.svg';

import styles from './LoginLogo.module.scss';

function LoginLogo() {
  return (
    <img src={logo} alt='Logo' className={styles['login-logo']} />
  );
}

export default LoginLogo;
