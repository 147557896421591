import logo from '../../../../assets/logo.svg';
import styles from './NewChatInfo.module.scss';

function NewChatInfo() {
  return (
    <div className={styles['new-chat-info']}>
      <div className={styles['new-chat-info__mailes']}>M<span className={styles['new-chat-info__mailes__ai']}>AI</span>LES</div>
      <div className={styles['new-chat-info__text']}>
        Start your new chat here.
      </div>
    </div>
  );
}

export default NewChatInfo;
