// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarContent_sidebar-content__jYQ5h {
  flex-grow: 1;
}
.SidebarContent_sidebar-content__title__4-3PS {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding-left: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/Sidebar/components/SidebarContent/SidebarContent.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAGE;EACE,yBAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AADJ","sourcesContent":["@import '../../../../scss/variables';\n\n.sidebar-content {\n  flex-grow: 1;\n\n  &__title {\n    text-transform: uppercase;\n    font-size: 1.1rem;\n    font-weight: bold;\n    margin-bottom: 0.5rem;\n    padding-left: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-content": `SidebarContent_sidebar-content__jYQ5h`,
	"sidebar-content__title": `SidebarContent_sidebar-content__title__4-3PS`
};
export default ___CSS_LOADER_EXPORT___;
