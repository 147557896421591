import LoginWrapper from './components/LoginWrapper/LoginWrapper';
import LoginLogo from './components/LoginLogo/LoginLogo';
import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import LoginButton from './components/LoginButton/LoginButton';

function Login() {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated 
  ? <Navigate to='/' /> 
  :  <LoginWrapper>
      <LoginLogo />
      <LoginButton />
    </LoginWrapper>;
}

export default Login;
