import { createContext } from 'react';
import { AiResponseSource } from '../models/AiResponseModel';

const AiResponseSourceContext = createContext<{
  source: AiResponseSource[] | undefined;
  setSource: React.Dispatch<
    React.SetStateAction<AiResponseSource[] | undefined>
  >;
}>({
  source: [],
  setSource: () => {},
});

export default AiResponseSourceContext;
