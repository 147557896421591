// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper_wrapper__GC3yJ {
  margin: 0 auto;
  width: clamp(400px, 50vw, 900px);
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/Wrapper/Wrapper.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gCAAA;EACA,aAAA;EACA,sBAAA;AACF","sourcesContent":[".wrapper {\n  margin: 0 auto;\n  width: clamp(400px, 50vw, 900px);\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Wrapper_wrapper__GC3yJ`
};
export default ___CSS_LOADER_EXPORT___;
