import logo from '../../assets/logo-small.svg';

import styles from './Avatar.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  aiLogo?: boolean;
  className?: string;
}

function Avatar({ aiLogo, className }: Props) {
  return (
    <div className={`${styles.avatar} ${className}`}>
      {aiLogo ? (
        // <img
        //   src={logo}
        //   alt='AI message indicator'
        //   className={styles.avatar__logo}
        // />
        'AI'
      ) : (
        'You'
      )}
    </div>
  );
}

export default Avatar;
