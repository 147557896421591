import msalInstance from '../msalInstance';
import { getUserId } from '../mockData/user';

const getAccesToken = async () => {
    return getUserId()
    const accounts = msalInstance.getAllAccounts();

    if(!accounts || accounts.length === 0) {
        console.error("Token acquisition error: No user account found")
        return null

    }

    const tokenRequest = {
      account: accounts[0],
      scopes: ["api://24e195d8-5a7e-4c35-8505-52d82ae1c917/MilesAI.Api.Read"],
    };
  
    try {
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      return response.accessToken;
    } catch (error) {
      console.error('Token acquisition error:', error);
    }
  };

export default getAccesToken;